import React, { Component } from 'react'
import { Route, Switch, withRouter } from "react-router-dom";

import { Page404 } from "./components/misc/Page404";

// Pages
import Home from './components/pages/Home'
import About from './components/pages/About';
import Media from './components/pages/Media';
import Events from './components/pages/Events';
import Alliances from './components/pages/Alliances';

class Routes extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/about">
                    <About />
                </Route>
                <Route exact path="/media">
                    <Media />
                </Route>
                <Route exact path="/events">
                    <Events />
                </Route>
                <Route exact path="/alliances">
                    <Alliances />
                </Route>
                <Route>
                    <Page404 />
                </Route>
            </Switch>
        )
    }
}

export default withRouter(Routes);